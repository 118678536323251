import './index.scss'

import React from 'react'
import T from 'prop-types'
import BEMHelper from 'react-bem-helper'
import Img from 'gatsby-image'

import { Container } from '@components'
import Button from '../Button'

const bem = new BEMHelper('entries')

export default function Entries({ items }) {
  return (
    <Container node="ul" {...bem('')}>
      {items.map(({ title, to, label, image }, index) => (
        <li key={to || index} {...bem('item', { action: to && label })}>
          <Img fluid={{ ...image.fluid }} {...bem('image')} alt={title} />
          <h2 {...bem('title')}>{title}</h2>

          {to && label && (
            <Button to={to} primary spaceless {...bem('action')}>
              {label}
            </Button>
          )}
        </li>
      ))}
    </Container>
  )
}

Entries.propTypes = {
  items: T.array.isRequired,
}
