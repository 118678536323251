export default {
  full: {
    id: 'full',
    cta: 'Prøv gratis',
  },
  recipes: {
    id: 'recipes',
    name: 'Oppskrifter',
    price: 49,
    includes: ['Alle oppskfiter', 'Handleliste', 'Utstyrsguide', 'Ukesmenyer'],
    cta: 'Kjøp',
  },
  // full: {
  //   id: 'full',
  //   name: 'Oppskrifter og kurs',
  //   price: 89,
  //   includes: [
  //     'Alle oppskfiter',
  //     'Alle kurs',
  //     'Handleliste',
  //     'Utstyrsguide',
  //     'Ukesmenyer',
  //   ],
  //   cta: 'Kjøp',
  // },
  free: {
    id: 'free',
    name: 'Smaksprøve',
    price: 0,
    preamble:
      'Registrer deg og last ned appen, så får du tilgangt til et utvalg oppskrifter og kurs.',
    cta: 'Prøv gratis',
  },
}
