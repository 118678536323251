import React from 'react'
import T from 'prop-types'
import { graphql } from 'gatsby'

import SEO from '../components/SEO'
import Hero from '../components/Hero'
import Entries from '../components/Entries'
import Propaganda from '../components/Propaganda'
// Intro video
import frontpagePoster from '../assets/videoes/frontpage.jpg'
import frontpageWEBM from '../assets/videoes/frontpage.webm'
import frontpageMP4 from '../assets/videoes/frontpage.mp4'

export default function Page({
  data: { recipesImage, coursesImage, products },
}) {
  return (
    <>
      <SEO title="Oppskrifter og kurs designet for mobilen" />
      <Hero
        title="Oppskrifter og kurs designet for mobilen"
        action={{ label: 'Last ned appen', to: '/last-ned' }}
        video={{
          poster: frontpagePoster,
          webm: frontpageWEBM,
          mp4: frontpageMP4,
        }}
      />
      <Entries
        items={[
          {
            title:
              'Stegvise oppskrifter med videoer som gjør det lett å lage god mat.',
            to: '/oppskrifter',
            label: 'Mer om oppskrifter',
            image: recipesImage.image,
          },
          {
            title:
              'Korte videokurs med kunnskap og tips som gjør deg tryggere på kjøkkenet.',
            to: '/kurs',
            label: 'Mer om kurs',
            image: coursesImage.image,
          },
        ]}
      />
      <Propaganda products={products.products} />
    </>
  )
}

Page.propTypes = {
  data: T.shape({
    coverImage: T.object.isRequired,
    recipesImage: T.object.isRequired,
    coursesImage: T.object.isRequired,
    products: T.object.isRequired,
  }).isRequired,
}

export const query = graphql`
  query {
    # coverImage: file(relativePath: { eq: "app-frontpage.jpg" }) {
    #   image: childImageSharp {
    #     fluid(maxWidth: 620) {
    #       ...GatsbyImageSharpFluid
    #     }
    #   }
    # }
    recipesImage: file(relativePath: { eq: "nuddel-biff.jpg" }) {
      image: childImageSharp {
        fluid(maxWidth: 620) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    coursesImage: file(relativePath: { eq: "matpakkebrod.jpg" }) {
      image: childImageSharp {
        fluid(maxWidth: 620) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    products: foodsteps {
      products {
        id
        title
        description
        includes
        variants {
          promotion {
            price
            title
            id
            description
          }
          price
          months
        }
      }
    }
  }
`
